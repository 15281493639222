const MenuList = [
    {
        id: 1,
        title: "SUPERPANCHO",
        simplePrice: "$1300",
        completePrice: "",
    },
    {
        id: 2,
        title: "HAMBURGUESA",
        simplePrice: "$2800",
        completePrice: "$3200",
    },
    {
        id: 3,
        title: "HAMBURGUESA DOBLE",
        simplePrice: "$3900",
        completePrice: "$4300",
    },
    {
        id: 4,
        title: "HAMBURGUESA TRIPLE",
        simplePrice: "$4900",
        completePrice: "$5300",
    },
    {
        id: 5,
        title: "HAMBURGUESA DE POLLO",
        simplePrice: "$2800",
        completePrice: "$3200",
    },
    {
        id: 6,
        title: "HAMB. DE POLLO DOBLE",
        simplePrice: "$3900",
        completePrice: "$4300",
    },
    {
        id: 7,
        title: "MEGA HAMBURGUESA",
        simplePrice: "$3400",
        completePrice: "$3800",
    },
    {
        id: 8,
        title: "MEGA HAMB. DOBLE",
        simplePrice: "$4500",
        completePrice: "$4900",
    },
    {
        id: 9,
        title: "MEGA HAMB. TRIPLE",
        simplePrice: "$5400",
        completePrice: "$5800",
    },
    {
        id: 10,
        title: "HAMBURGUESA GOURMET",
        simplePrice: "$3900",
        completePrice: "$4300",
    },
    {
        id: 11,
        title: "HAMB. GOURMET DOBLE",
        simplePrice: "$5100",
        completePrice: "$5500",
    },
    {
        id: 12,
        title: "MILANESA DE CARNE",
        simplePrice: "$3800",
        completePrice: "$4200",
    },
    {
        id: 13,
        title: "MILANESA DE POLLO",
        simplePrice: "$3800",
        completePrice: "$4200",
    },
    {
        id: 14,
        title: "NUGGETS DE POLLO",
        simplePrice: "$3900",
        completePrice: "",
    },
    {
        id: 15,
        title: "POLLO GRILL",
        simplePrice: "$3800",
        completePrice: "$4200",
    },
    {
        id: 16,
        title: "LOMITO",
        simplePrice: "$4600",
        completePrice: "$5000",
    },
    {
        id: 17,
        title: "PAPAS TRADICIONALES",
        simplePrice: "$1900",
        completePrice: "",
    },
    {
        id: 18,
        title: "PAPAS NOISETTE",
        simplePrice: "$2300",
        completePrice: "",
    },
    {
        id: 19,
        title: "PAPAS SMILES",
        simplePrice: "$2300",
        completePrice: "",
    },
    {
        id: 20,
        title: "PAPAS CORTE CASERO",
        simplePrice: "$2300",
        completePrice: "",
    },

];

export default MenuList;